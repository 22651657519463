<template>
  <div class="fullscreen">
    <div class="center vertical-center">
        <h1 class="primary huge">404</h1>
        <h1 class="primary" style="transform: translateY(-100px)">Page Not Found!</h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.fullscreen {
    height: 100vh;
    widows: 100vw;
    background-color: rgb(50, 50, 50);
}
.huge {
    font-size: 30rem;
}
</style>